import React, { useCallback, useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { toast } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";

const Withdrawal = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [Withdrawal, setWithdrawal] = useState({} as any);
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  useEffect(() => {
    handleFetchWithdrawal();
  }, []);

  const [comment, setComment] = useState("");

  const handleFetchWithdrawal = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/withdrawal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setWithdrawal(res.data);
    } catch (error) {
      toast.error("An error occurred while fetching Withdrawal");
    } finally {
      setLoading(false);
    }
  };

  const handleApproveWithdrawal = async () => {
   if (!comment) {
      toast.error("Please provide a reason for approving Withdrawal");
      return;
   }
    setApproving(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/withdrawal/${id}/approve`,
        {
          comment,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setWithdrawal(res.data);
      toast.success("Withdrawal approved successfully");
    } catch (error) {
      toast.error("An error occurred while approving Withdrawal");
    } finally {
      setApproving(false);
    }
  };

  const handleDeclineReject = async () => {
   if (!comment) {
      toast.error("Please provide a reason for declining Withdrawal");
      return;
   }
    setRejecting(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/withdrawal/${id}/reject`,
        {
          comment,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setWithdrawal(res.data);
      toast.success("Withdrawal declined successfully");
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while declining Withdrawal");
    } finally {
      setRejecting(false);
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Withdrawal" pageTitle="Withdrawal" />
      <ToastContainer closeButton={false} limit={1} />
      {loading ? <p>Loading...</p> : <> </>}
      {Withdrawal && (
        <div className="bg-white shadow-md rounded-md p-4 w-full">
          <div className="flex justify-between">
            <div>
              <p>
                <strong>Payment Method:</strong> {Withdrawal.paymentMethod}
              </p>
              {Withdrawal.paymentMethod === "Bank Transfer" ? (
                <>
                  <p>
                    <strong>Bank Name:</strong>{" "}
                    {Withdrawal?.bankDetails?.bankName}
                  </p>
                  <p>
                    <strong>Bank Account Name:</strong>{" "}
                    {Withdrawal.bankDetails?.accountName}
                  </p>
                  <p>
                    <strong>Bank Account Number:</strong>{" "}
                    {Withdrawal.bankDetails?.accountNumber}
                  </p>
                </>
              ) : (
                <p>
                  <strong>Wallet Address:</strong>{" "}
                  {Withdrawal?.usdtDetails?.address}
                </p>
              )}
              <p>
                <strong> Amount:</strong> {Withdrawal.amount}
              </p>

              <p>
                <strong>Status:</strong> {Withdrawal.status}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {moment(Withdrawal.createdAt).format("DD/MM/YYYY - HH:mm:ss")}
              </p>
              <p>
                <strong> Updated At:</strong>{" "}
                {moment(Withdrawal.updatedAt).format("DD/MM/YYYY - HH:mm:ss")}
              </p>
            </div>
            {Withdrawal.status === "Pending" ? (
              <div className="flex gap-4 h-fit p-4 bg-gray-100 rounded-lg shadow-sm">
                <div className="flex flex-col gap-2 w-full">
                  <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="w-full p-2 min-h-[120px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
                    placeholder="Enter your comment..."
                  />
                  <div className="flex justify-between">
                    <button
                      disabled={approving}
                      onClick={handleApproveWithdrawal}
                      className={`w-full py-2 rounded-md text-white ${
                        approving
                          ? "bg-gray-300"
                          : "bg-green-500 hover:bg-green-600"
                      } transition duration-300 ease-in-out`}
                    >
                      {approving ? "Approving..." : "Approve"}
                    </button>
                    <button
                      disabled={rejecting}
                      onClick={handleDeclineReject}
                      className={`w-full py-2 rounded-md text-white ${
                        rejecting
                          ? "bg-gray-300"
                          : "bg-red-500 hover:bg-red-600"
                      } transition duration-300 ease-in-out ml-2`}
                    >
                      {rejecting ? "Declining..." : "Reject"}
                    </button>
                  </div>
                </div>
              </div>
            ) : Withdrawal.status === "Rejected" ? (
              <div className="bg-red-500 text-white px-4 py-2 rounded-md h-fit">
                <p className="text-white rounded-md">Rejected</p>
              </div>
            ) : (
              <div className="bg-green-500 text-white px-4 py-2 rounded-md h-fit">
                <p className="text-white rounded-md">Approved</p>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Withdrawal;
