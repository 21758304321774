import { Search } from "lucide-react";
import React, { useEffect } from "react";
import BreadCrumb from "Common/BreadCrumb";
import axios from "axios";
import { Loader } from "lucide-react";
import TableContainer from "Common/TableContainer";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "Common/Components/Dropdown";
import { MoreHorizontal } from "lucide-react";
import { Eye } from "lucide-react";
import toast from "react-hot-toast";

const Refferals = () => {
  const [loading, setLoading] = React.useState(false);
  const [referrals, setReferrals] = React.useState([]);
  const [updating, setUpdating] = React.useState(false);

  useEffect(() => {
    handleGetReferrals();
  }, []);

  const handleGetReferrals = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/ibrequest`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setReferrals(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (id: string) => {
    try {
      setUpdating(true);
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/ibrequest/reject/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      handleGetReferrals();
      toast.success("IB Request rejected successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to reject IB Request");
    } finally {
      setUpdating(false);
    }
  };

  const handleApprove = async (id: string) => {
    try {
      setUpdating(true);
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/ibrequest/approve/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      handleGetReferrals();
      toast.success("IB Request approved successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to approve IB Request");
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "IB Request ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <>
            <p className="text-slate-500 dark:text-zink-200">
              {cell.getValue()}
            </p>
          </>
        ),
      },
      {
        header: "User ID",
        accessorKey: "userId._id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <Link
              to={`/user-details/${cell.row.original.userId._id}`}
              className="transition-all duration-150 ease-linear order_id text-custom-500 hover:text-custom-600"
            >
              {cell.getValue()}
            </Link>
          </>
        ),
      },
      {
        header: "User Name",
        accessorKey: "userId.name",
        enableColumnFilter: false,
      },
      {
        header: "User Email",
        accessorKey: "userId.email",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction1"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="orderAction1"
            >
              <li>
                <button 
                disabled={updating}
                onClick={() => handleApprove(cell.row.original._id)}
                className="block w-full px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <span className="align-middle">
                    {updating ? "Updating..." : "Approve"}
                  </span>
                </button>
              </li>
              <li>
                <button
                disabled={updating}
                onClick={() => handleReject(cell.row.original._id)}
                className="block w-full px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <span className="align-middle">
                    {updating ? "Updating..." : "Reject"}
                  </span>
                </button>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );
  

  return (
    <React.Fragment>
      <BreadCrumb pageTitle="Referrals" title="All Referrals" />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      {referrals && referrals.length > 0 ? (
        <TableContainer
          isPagination={true}
          columns={columns || []}
          data={referrals || []}
          customPageSize={10}
          divclassName="mt-5 overflow-x-auto"
          tableclassName="w-full whitespace-nowrap"
          theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
          thclassName="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:border-zink-500 dark:text-zink-200"
          tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
          PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
        />
      ) : (
        <div className="noresult">
          <div className="py-6 text-center">
            <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
            <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
            <p className="mb-0 text-slate-500 dark:text-zink-200">
              We've searched all referrals, but we did not find any referrals
              for your search.
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Refferals;
