import React, { useState, useEffect } from "react";
import axios from "axios";
import PhotosUploader from "components/Forms/ImageUploader";
import { useAuthStore } from "store/useAuthStore";
import toast from "react-hot-toast";

const BackofficeSettings = () => {
  const { user } = useAuthStore();
  const [logos, setLogos] = useState(
    user?.backoffice?.logo ? [user?.backoffice?.logo] : []
  );
  const [favicon, setFavicon] = useState(
    user?.backoffice?.favicon ? [user?.backoffice?.favicon] : []
  );
  const [emailTemplateLogos, setEmailTemplateLogos] = useState(
    user?.backoffice?.emailTemplate?.logo ?
      [user?.backoffice?.emailTemplate?.logo] :
      []
  );
  const [settings, setSettings] = useState({
    name: user?.backoffice?.name || "",
    logo: user?.backoffice?.logo || "",
    favicon: user?.backoffice?.favicon || "",
    smtpSettings: {
      email: user?.backoffice?.smtpSettings?.email || "",
      appPassword: user?.backoffice?.smtpSettings?.appPassword || "",
    },
    emailNotifications: user?.backoffice?.emailNotifications || false,
    emailVerification: user?.backoffice?.emailVerification || false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSMTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      smtpSettings: {
        ...prevSettings.smtpSettings,
        [name]: value,
      },
    }));
  };

  const handleToggle = (name: string) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: !prevSettings[name as keyof typeof prevSettings],
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/backoffice`,
        settings,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Backoffice settings updated successfully.");
    } catch (error) {
      console.error("Error updating backoffice settings:", error);
      toast.error("Error updating backoffice settings.");
    }
  };

  useEffect(() => {
    if (logos) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        logo: logos[0],
      }));
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        logo: "",
      }));
    }
    if (favicon) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        favicon: favicon[0],
      }));
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        favicon: "",
      }));
    }

    if (emailTemplateLogos) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        emailTemplate: {
          logo: emailTemplateLogos[0],
        },
      }));
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        emailTemplate: {
          logo: "",
        },
      }));
    }
  }, [logos, favicon, emailTemplateLogos]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-15">Backoffice Settings</h6>
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium"
                >
                  Company Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={settings.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-custom-500"
                />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium">Logo (256x60)</label>
                <PhotosUploader
                  addedPhotos={logos}
                  //@ts-ignore
                  onChange={setLogos}
                  maxPhotos={1}
                  type="logo"
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium">
                  Favicon (16x16)
                </label>
                <PhotosUploader
                  addedPhotos={favicon}
                  //@ts-ignore
                  onChange={setFavicon}
                  maxPhotos={1}
                  type="favicon"
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium">
                  Email Template Logo 
                </label>
                <PhotosUploader
                  addedPhotos={emailTemplateLogos}
                  //@ts-ignore
                  onChange={setEmailTemplateLogos}
                  maxPhotos={1}
                  type="image"
                />
              </div>

              <div>
                <h4 className="text-15 mb-2">SMTP Settings</h4>
                <div className="space-y-2">
                  <div>
                    <label htmlFor="smtpEmail" className="block mb-1 text-sm">
                      Email
                    </label>
                    <input
                      type="email"
                      id="smtpEmail"
                      name="email"
                      value={settings.smtpSettings.email}
                      onChange={handleSMTPChange}
                      className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-custom-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="smtpPassword"
                      className="block mb-1 text-sm"
                    >
                      App Password
                    </label>
                    <input
                      type="password"
                      id="smtpPassword"
                      name="appPassword"
                      value={settings.smtpSettings.appPassword}
                      onChange={handleSMTPChange}
                      className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-custom-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="text-15">Email Verification</h4>
                  <p className="mt-1 text-slate-500 dark:text-zink-200">
                    If enabled, it will require users to verify their email
                    address before they can log in.
                  </p>
                </div>
                <div className="shrink-0">
                  <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
                    <input
                      type="checkbox"
                      name="emailVerification"
                      id="emailVerification"
                      checked={settings.emailVerification}
                      onChange={() => handleToggle("emailVerification")}
                      className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-600 bg-white/80 dark:bg-zink-400 peer/published checked:bg-custom-500 dark:checked:bg-custom-500 ltr:checked:right-0 rtl:checked:left-0 checked:border-custom-100 dark:checked:border-custom-900 arrow-none checked:bg-none"
                    />
                    <label
                      htmlFor="emailVerification"
                      className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-100 dark:peer-checked/published:bg-custom-900 peer-checked/published:border-custom-100 dark:peer-checked/published:border-custom-900"
                    ></label>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="text-15">Email notifications</h4>
                  <p className="mt-1 text-slate-500 dark:text-zink-200">
                    Clients and Admin will Receive email notifications for
                    various transactions.
                  </p>
                </div>
                <div className="shrink-0">
                  <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
                    <input
                      type="checkbox"
                      name="emailNotifications"
                      id="emailNotifications"
                      checked={settings.emailNotifications}
                      onChange={() => handleToggle("emailNotifications")}
                      className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-600 bg-white/80 dark:bg-zink-400 peer/published checked:bg-custom-500 dark:checked:bg-custom-500 ltr:checked:right-0 rtl:checked:left-0 checked:border-custom-100 dark:checked:border-custom-900 arrow-none checked:bg-none"
                    />
                    <label
                      htmlFor="emailNotifications"
                      className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-100 dark:peer-checked/published:bg-custom-900 peer-checked/published:border-custom-100 dark:peer-checked/published:border-custom-900"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="px-4 py-2 text-white bg-custom-500 rounded hover:bg-custom-600 focus:outline-none focus:ring-2 focus:ring-custom-500 focus:ring-opacity-50"
              >
                Save Settings
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BackofficeSettings;
