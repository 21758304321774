import React, { useCallback, useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { toast } from "react-hot-toast";
import { useAuthStore } from "store/useAuthStore";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdModeEdit } from "react-icons/md";
import Modal from "Common/Components/Modal";

const Deposit = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [deposit, setDeposit] = useState({} as any);
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (deposit && deposit.amount) {
      setAmount(deposit.amount);
    } 
  }, [deposit]);

  const toggleEdit = () => setIsEdit(!isEdit);

  useEffect(() => {
    handleFetchDeposit();
  }, []);

  const handleFetchDeposit = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/deposit/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDeposit(res.data);
    } catch (error) {
      toast.error("An error occurred while fetching deposit");
    } finally {
      setLoading(false);
    }
  }, []);

  const [changingAmount, setChangingAmount] = useState(false);

  const handleChangeAmount = async () => {
    setChangingAmount(true);
    try {
      console.log(amount);
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/deposit/${id}/amount`,
        {
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDeposit(res.data);
      toast.success("Amount changed successfully");
    } catch (error) {
      toast.error("An error occurred while changing amount");
    } finally {
      setChangingAmount(false);
    }
  };

  const handleApproveDeposit = useCallback(async () => {
    setApproving(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/deposit/${id}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDeposit(res.data);
      toast.success("Deposit approved successfully");
    } catch (error) {
      toast.error("An error occurred while approving deposit");
    } finally {
      setApproving(false);
    }
  }, []);
  const handleDeclineReject = useCallback(async () => {
    setApproving(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/deposit/${id}/reject`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDeposit(res.data);
      toast.success("Deposit declined successfully");
    } catch (error) {
      toast.error("An error occurred while declining deposit");
    } finally {
      setRejecting(false);
    }
  }, []);

  return (
    <React.Fragment>
      <BreadCrumb title="Deposit" pageTitle="Deposit" />
      <ToastContainer closeButton={false} limit={1} />
      {loading ? <p>Loading...</p> : <> </>}
      {deposit && (
        <div className="bg-white shadow-md rounded-md p-4 w-full">
          <div className="flex justify-between">
            <div className="flex flex-col gap-4 w-full">
              <p>
                <strong>Payment Method:</strong> {deposit.paymentMethod}
              </p>
              <p className="flex gap-2 items-center" onClick={toggleEdit}>
                <strong> Amount:</strong> {deposit.amount}
                <span className="text-blue-500 cursor-pointer whitespace-nowrap flex gap-2">
                  Edit <MdModeEdit />
                </span>
              </p>
              <p>
                <strong>Payment Proof:</strong>{" "}
                <a
                  className="text-blue-500"
                  href={deposit.paymentProof}
                  target="_blank"
                  rel="noreferrer"
                >
                  View
                </a>
              </p>
              <p>
                <strong>Status:</strong> {deposit.status}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {moment(deposit.createdAt).format("lll")}
              </p>
              <p>
                <strong> Updated At:</strong>{" "}
                {moment(deposit.updatedAt).format("lll")}
              </p>
            </div>
            {deposit.status === "Pending" ? (
              <div className="flex gap-4 h-fit">
                <button
                  disabled={approving}
                  onClick={handleApproveDeposit}
                  className="bg-green-500 text-white px-4 py-2 rounded-md"
                >
                  {approving ? "Approving..." : "Approve"}
                </button>
                <button
                  disabled={rejecting}
                  onClick={handleDeclineReject}
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                >
                  {rejecting ? "Declining..." : "Decline"}
                </button>
              </div>
            ) : deposit.status === "Rejected" ? (
              <div className="bg-red-500 text-white px-4 py-2 rounded-md h-fit">
                <p className="text-white rounded-md">Rejected</p>
              </div>
            ) : (
              <div className="bg-green-500 text-white px-4 py-2 rounded-md h-fit">
                <p className="text-white rounded-md">Approved</p>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        show={isEdit}
        onHide={toggleEdit}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Make a Withdrawal"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleChangeAmount();
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="amountInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Amount
                </label>
                <input
                  type="number"
                  id="amountInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="12345 67890"
                  name="amount"
                  onChange={(e) => {
                    setAmount(Number(e.target.value));
                  }}
                  value={amount}
                />
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggleEdit}
                disabled={changingAmount}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {changingAmount ? "Changing..." : "Change"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Deposit;
